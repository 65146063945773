import { fetch } from './fetch'
import api from './url'

export default {
  giftlist: function (params) {
    return fetch({
      url: api.giftlist,
      method: 'get',
      params: params
    })
  },
  giftformapply: function (params) {
    return fetch({
      url: api.giftformapply,
      method: 'post',
      data: params
    })
  },
  proToSite: function (params) {
    return fetch({
      url: api.proToSite,
      method: 'get',
      params: params
    })
  },
  getgrad: function (params) {
    return fetch({
      url: api.getgrad,
      method: 'get',
      params: params
    })
  },
  savegrad: function (params) {
    return fetch({
      url: api.savegrad,
      method: 'post',
      data: params
    })
  },
  usersavegrad: function (params) {
    return fetch({
      url: api.usersavegrad,
      method: 'post',
      data: params
    })
  },
  elementTrans: function (params) {
    return fetch({
      url: api.elementTrans,
      method: 'get',
      params: params
    })
  },
}