import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import md5 from 'js-md5'
// 样式重置
import './assets/css/reset.styl'
import api from './axios/api'

// 调用app方法
// import Bridge from "./utils/util"

const myApp = createApp(App)

myApp.config.globalProperties.$axios = axios
// myApp.config.globalProperties.$bridge = Bridge
myApp.config.globalProperties.$md5 = md5
myApp.config.globalProperties.api = api

myApp.use(router).mount('#app')
