const index = () => import('../page/scoredindex.vue')
const giftform = () => import('../page/giftform')
const father = () => import('../page/father')
export default [
    {
        path: '/',
        component: index,
    },
    {
        path: '/gift/:id',
        component: giftform,
    },
    {
        path: '/father',
        component: father,
    }
]