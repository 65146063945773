<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {
  }
}
</script>

<style>
#app {
  text-align: center;
  width: 100%;
  background-color: #1C222D;
  height: 100%;
  overflow: auto;
  /* margin-bottom: 40px; */
  padding-bottom: 40px;
}
</style>
