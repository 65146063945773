// 设置api
let api = '/v2/'
let s3 = '/v3/cloud/'

export default {

// H5
// 产品对应站点
proToSite: `${api}invite_comments/products_to_site`, 

// 礼物列表
giftlist: `${api}invite_comments/reward_list`, 

// 礼品申请预签名
giftformapply: `${api}invite_comments/apply_for_gift`, 

// 评分保存
savegrad: `${api}invite_comments/save_grade`, 
usersavegrad: `${api}invite_comments/user_save_grade`, 

// 获取评分
getgrad: `${api}invite_comments/activity`, 

// 页面翻译
elementTrans: `${api}invite_comments/app_language`

}