/* eslint-disable */
import axios from 'axios'
// import store from '../store'
import Cryptojs from 'crypto-js'

export function fetch(options){
  return new Promise((resolve, reject) => {
    // 设置请求头
    var header = {
      'Content-Type': 'application/json',
      'Appid': 'd0a4667742d0e7d7',
      uid: '',
      api_token: ''
    }
    // 登陆后----uid  &&  api_token
    // if (store.state.login.isLogin)
    // header = combineObj(store.state.login.adminer, header)
    // if (options.headers)
    // header = combineObj(options.headers, header)

    header = getHeader(header, options)
    // 初始化axios
    const instance = axios.create({
      headers: header,
      transformRequest: [function(data) {
        data = JSON.stringify(data)
        return data
      }],
      // paramsSerializer: function(params) {
      //   return JSON.stringify(params)
      // },
      timeout: 300 * 1000
    });

    // then 请求成功之后进行什么操作
    instance(options).then(response => {
      resolve(response)
    }).catch(error => {
        reject(error)
    });
  });
}

function getSign(appid,secret,timestamp)
{
  var str =  appid + timestamp
  var hash = Cryptojs.HmacSHA256(str, secret)
  var hashInBase64 = hash.toString()
  var jiamistr = Cryptojs.enc.Utf8.parse(hashInBase64)
  var base64 = Cryptojs.enc.Base64.stringify(jiamistr)
  return base64
}

function getHeader(header, option) {
  // 1. 获取当前 10 位时间戳
  var tmp = Date.parse(new Date()).toString();
  var timestamp = tmp.substr(0,10);
  // 2. 获取当前请求参数 JSON 字符串
  var jsonParam = ""
  if (option.method == "get") {
    for (var o in option.params) {
      option.params[o] = option.params[o]+""
    }
    if (option.params != undefined && Object.keys(option.params).length > 0) {
      jsonParam = JSON.stringify(option.params);
    }
  } else {
    if (option.data != undefined && Object.keys(option.data).length > 0) {
      jsonParam = JSON.stringify(option.data);
    }
  }
  // 4. 生成签名字符串
  // var sign = getSign('d0a4667742d0e7d7','8c56b4dfd0a4667742d0e7d7712ea9af',timestamp)  // 线上
  var sign = getSign('d0a4667742d0e7d7','8c56b4dfd0a4667742d0e7d7712ea9af',timestamp) // 本地测试
  return combineObj({"timestamp":timestamp, "sign":sign}, header)
}

function combineObj(o,n) {
    for (var p in n) {
        if(n.hasOwnProperty(p) && (!o.hasOwnProperty(p)))
            o[p]=n[p]
    }

    return o
}
